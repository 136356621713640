import React from "react";
import {
  FaInstagram,
  FaTiktok,
  FaEnvelope,
  FaFacebook,
  FaWhatsapp,
  FaPhone,
} from "react-icons/fa";
import { MdExpandMore, MdExpandLess, MdOutlineTimer } from "react-icons/md";
import { TbTooltip, TbCirclePlus, TbCircleMinus  } from "react-icons/tb";
import { CgClose } from "react-icons/cg";
import { FiMenu } from "react-icons/fi";
import { LuArrowBigRightDash, LuArrowBigLeftDash } from "react-icons/lu";
import { SlGlobe } from "react-icons/sl";
import { PiLifebuoyDuotone } from "react-icons/pi";
import { BiSpa } from "react-icons/bi";
import { HiHome } from "react-icons/hi2";


import PropTypes from "prop-types";
import "./icon.scss";

// Create a mapping from icon names to their components
const iconMapping = {
  instagram: FaInstagram,
  tiktok: FaTiktok,
  facebook: FaFacebook,
  phone: FaPhone,
  email: FaEnvelope,
  whatsapp: FaWhatsapp,
  expandMore: MdExpandMore,
  expandLess: MdExpandLess,
  seeMore: TbTooltip,
  close: CgClose,
  menu: FiMenu,
  duration: MdOutlineTimer,
  arrowright: LuArrowBigRightDash,
  arrowleft: LuArrowBigLeftDash,
  language: SlGlobe,
  lifebuoy: PiLifebuoyDuotone,
  circleplus: TbCirclePlus,
  circleminus: TbCircleMinus,
  spa: BiSpa,
  home: HiHome
};

const Icon = ({ name, link, target, label, className }) => {
  const IconComponent = iconMapping[name];
  if (!IconComponent) {
    // If no icon found for the given name, you could return null or a default icon
    return null;
  }

  return (
    <a
      href={link}
      className={`icon ${className}`}
      aria-label={label}
      title={label}
      target= {target}
      rel="noopener noreferrer"
    >
      <IconComponent />
    </a>
  );
};

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(iconMapping)).isRequired,
  link: PropTypes.string,
  target: PropTypes.string,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Icon.defaultProps = {
  className: "",
  target: "_blank"
};

export default Icon;
