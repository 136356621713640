import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../Icon/Icon"; // Adjust the import path as necessary
import './languageSwitcher.scss';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const handleLanguageChange = () => {
    const newLang = language === "en" ? "nl" : "en";
    i18n.changeLanguage(newLang);
    setLanguage(newLang);
  };

  return (
    <div className="language-switcher" onClick={handleLanguageChange}>
      <Icon
        name={"language"}
        label={`Switch Language to ${language === "en" ? "Dutch" : "English"}`}
        className="language"
      />
    </div>
  );
};

export default LanguageSwitcher;
