import React, { useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import Modal from "../../Modal/Modal";
import Button from "../../atoms/Button/Button";
import Text from "../../atoms/Text/Text";
import { useTranslation } from "react-i18next";
import "./bookingButton.scss"; // Ensure you have created and imported bookingButton.scss
import Icon from "../../atoms/Icon/Icon";

const BookingButton = ({ link }) => { // Accept a link prop
  const [isModalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();

  const handleButtonClick = () => {
    setModalOpen(true);
  };

  return (
    <>
      <Button
        variant="primary"
        className="booking-btn"
        onClick={handleButtonClick}
        aria-label="Book an appointment"
      >
        <Icon name="spa" label="Flower Icon" className={"spa-icon"} />
        <Text variant="p" className="booking-text">{t("treatments.booknow")}</Text>
      </Button>
      {isModalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          <iframe
            src={link} // Use the link prop for the iframe src
            title="Booking Widget"
            className="booking-iframe"
            allow="fullscreen" // Allow fullscreen mode if necessary
          ></iframe>
        </Modal>
      )}
    </>
  );
};

// Define the prop types for the BookingButton
BookingButton.propTypes = {
  link: PropTypes.string.isRequired, // Define link as a required string prop
};

export default BookingButton;
