const treatmentsData = [
  {
    title: "Women Deluxe",
    description: "treatments.treatmentCards.womenDeluxe.description",
    duration: "60 min",
    price: "€70,-",
    steps: [
      "analysis",
      "massage",
      "cleanse",
      "masker",
      "conditioning",
      "oil",
      "drying",
    ].map((step) => ({
      header: `treatments.treatmentCards.womenDeluxe.steps.${step}.header`,
      detail: `treatments.treatmentCards.womenDeluxe.steps.${step}.detail`,
    })),
    link: "https://headwellness.zohobookings.eu/portal-embed#/186536000000031054"
  },
  {
    title: "Women Ultra Deluxe",
    description: "treatments.treatmentCards.womenUltraDeluxe.description",
    duration: "90 min",
    price: "€95,-",
    steps: [
      "analysis",
      "massage",
      "cleanse",
      "masker",
      "neckmassage",
      "conditioning",
      "oil",
      "guasha",
      "drying",
    ].map((step) => ({
      header: `treatments.treatmentCards.womenUltraDeluxe.steps.${step}.header`,
      detail: `treatments.treatmentCards.womenUltraDeluxe.steps.${step}.detail`,
    })),
    link: "https://headwellness.zohobookings.eu/portal-embed#/186536000000087102"
  },
  {
    title: "Men Deluxe",
    description: "treatments.treatmentCards.menDeluxe.description",
    duration: "60 min",
    price: "€60,-",
    steps: [
      "analysis",
      "massage",
      "cleanse",
      "masker",
      "conditioning",
      "oil",
      "drying",
    ].map((step) => ({
      header: `treatments.treatmentCards.menDeluxe.steps.${step}.header`,
      detail: `treatments.treatmentCards.menDeluxe.steps.${step}.detail`,
    })),
    link: "https://headwellness.zohobookings.eu/portal-embed#/186536000000087134"
  },
  {
    title: "Men Ultra Deluxe",
    description: "treatments.treatmentCards.menUltraDeluxe.description",
    duration: "90 min",
    price: "€85,-",
    steps: [
      "analysis",
      "massage",
      "cleanse",
      "masker",
      "neckmassage",
      "conditioning",
      "oil",
      "guasha",
      "beard",
      "drying",
    ].map((step) => ({
      header: `treatments.treatmentCards.menUltraDeluxe.steps.${step}.header`,
      detail: `treatments.treatmentCards.menUltraDeluxe.steps.${step}.detail`,
    })),
    link: "https://headwellness.zohobookings.eu/portal-embed#/186536000000147384"
  },
  {
    title: "Kids Deluxe",
    description: "treatments.treatmentCards.kidsDeluxe.description",
    duration: "30 min",
    price: "€40,-",
    rules: "treatments.treatmentCards.kidsDeluxe.rules",
    rules2: "treatments.treatmentCards.kidsDeluxe.rules2",
    link: "https://headwellness.zohobookings.eu/portal-embed#/186536000000087166"
  },
  {
    title: "treatments.treatmentCards.giftcard.title",
    description: "treatments.treatmentCards.giftcard.description",
    rules: "treatments.treatmentCards.giftcard.rules",
    rules2: "treatments.treatmentCards.giftcard.rules2",
    link: "https://headwellness.zohobookings.eu/portal-embed#/headwellnessgiftcards"
  },
  // Add more treatments as needed
];

export default treatmentsData;
