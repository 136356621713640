import React from "react";
import PropTypes from "prop-types";
import { animateScroll as scroll } from "react-scroll";
import Image from "../../atoms/Image/Image";
import logoImageMedium from "../../../assets/images/Logo/logo512.png";
import "./logo.scss";


const Logo = ({ onClick }) => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const handleLogoClick = (event) => {
    if (onClick) {
      onClick(event);
    }

    // Add clicked class for mobile feedback
    const logoElement = event.currentTarget;
    logoElement.classList.add("clicked");

    // Remove the clicked class after 300ms
    setTimeout(() => {
      logoElement.classList.remove("clicked");
    }, 600);
  };

  const handleKeyDown = (e) => {
    // The user is pressing either the "Enter" key or the " " (space) key
    if (e.key === "Enter" || e.key === " ") {
      scrollToTop();
    }
  };

  return (
    <div
      className="logo"
      onClick={handleLogoClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      role="button"
      aria-label="Scroll to top"
    >
      <picture>
        <Image
          src={logoImageMedium}
          srcSet={logoImageMedium}
          alt="Head Wellness Logo"
          title="Logo: Scroll up"
          className="logo-image" // You can use a class to style the image instead of inline styles.
        />
      </picture>
    </div>
  );
};

Logo.propTypes = {
  onClick: PropTypes.func,
};

export default Logo;
