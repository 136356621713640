import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "./en.json";
import nlTranslations from "./nl.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslations,
    },
    nl: {
      translation: nlTranslations,
    },
  },
  lng: "nl", // Setting the default language to Dutch
  fallbackLng: "en", // use en if the language file is not available
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
