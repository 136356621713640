import React from "react";
import Text from "../../atoms/Text/Text";
// import Timer from '../../molecules/Timer/Timer';
import { useTranslation } from "react-i18next";
import Button from "../../atoms/Button/Button";
import "./subheader.scss";

const Subheader = () => {
  const { t } = useTranslation();

  const handleButtonClick = () => {
    const treatmentElement = document.getElementById("treatmentslist");
    if (treatmentElement) {
      treatmentElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="subheader" role="region" aria-labelledby="subheader-title">
      <div className="subheader-content">
        <Text variant="h3" id="subheader-title">
          {t("subheader.welcome")}
        </Text>
        <Text variant="p">{t("subheader.description")}</Text>

        <Button variant="primary" onClick={handleButtonClick}>
          {t("subheader.reserveButton")}
        </Button>
      </div>
    </div>
  );
};

export default Subheader;
