// Image.js
import React from "react";
import PropTypes from "prop-types";
import "./image.scss";

const Image = ({ className, src, alt, srcSet, sizes }) => (
  <img
    className={`responsive-image ${className}`}
    src={src}
    alt={alt}
    srcSet={srcSet}
    sizes={sizes}
    loading="lazy"
  />
);

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  srcSet: PropTypes.string,
  sizes: PropTypes.string,
};

Image.defaultProps = {
  className: "",
  srcSet: "",
  sizes: "",
};

export default Image;
