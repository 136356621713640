import React, { useState } from "react";
import Text from "../../atoms/Text/Text";
import Image from "../../atoms/Image/Image";
import Icon from "../../atoms/Icon/Icon";
import SalonWebpSmall from "../../../assets/images/Salon/headwellness_salon400.webp";
import SalonJpgSmall from "../../../assets/images/Salon/headwellness_salon400.jpg";
import SalonWebpMedium from "../../../assets/images/Salon/headwellness_salon800.webp";
import SalonJpgMedium from "../../../assets/images/Salon/headwellness_salon800.jpg";
import SalonWebpLarge from "../../../assets/images/Salon/headwellness_salon1600.webp";
import SalonJpgLarge from "../../../assets/images/Salon/headwellness_salon1600.jpg";

import Button from "../../atoms/Button/Button";
import { useTranslation } from "react-i18next";
import "./about.scss";

const About = () => {
  const { t } = useTranslation();
  const [isBenefitsExpanded, setIsBenefitsExpanded] = useState(false);

  const renderBenefits = (benefitsList) => {
    return benefitsList.map((benefit, index) => {
      const [title, description] = benefit.split(":");
      return (
        <Text variant="li" key={index} className="__benefit-item">
          <Text variant="span" className="__benefit-title">
            {title.trim()}
          </Text>
          <Text variant="p">{description.trim()}</Text>
        </Text>
      );
    });
  };

  const toggleBenefitsExpanded = () => {
    setIsBenefitsExpanded(!isBenefitsExpanded);
  };

  return (
    <section id="about" className="about">
      <article>
        <div className="about-headwellness">
          <header>
            <Text variant="h1" className="__header">
              {t("about.header")}
            </Text>
            <Text variant="h2" className="__header">
              Head Wellness
            </Text>
          </header>

          <Text variant="p" className="__text">
            {t("about.description1")}
          </Text>
          <Text variant="p" className="__text __mission">
            {t("about.mission")}
          </Text>
        </div>

        <div className="about-treatment">
          <header>
            <Text variant="h2" className="__header">
              {t("about.treatment")}
            </Text>
          </header>
          <Text variant="p" className="__text">
            {t("about.treatmentDescription")}
          </Text>
          <div
            className={`readmore text-content ${
              isBenefitsExpanded ? "expanded" : "collapsed"
            }`}
          >
            <header>
              <Text variant="h3" className="__header">
                {t("about.benefitsHeader")}
              </Text>
            </header>
            <Text variant="ul" className="__benefits-list">
              {renderBenefits(t("about.benefitsList", { returnObjects: true }))}
            </Text>
            <Button onClick={toggleBenefitsExpanded} variant="tertiary">
              {isBenefitsExpanded ? (
                <Icon
                  name="expandLess"
                  label="Read less"
                  className="expandLess"
                />
              ) : (
                <Icon name="expandMore" label="expand" className="expandMore" />
              )}
            </Button>
          </div>
          <figure className="image-content">
            <picture>
              {/* WebP format for larger screens */}
              <source
                media="(min-width: 1550px)"
                srcSet={SalonWebpLarge}
                type="image/webp"
              />
              {/* Fallback JPG format for larger screens */}
              <source
                media="(min-width: 1550px)"
                srcSet={SalonJpgLarge}
                type="image/jpeg"
              />
              {/* WebP format for medium screens */}
              <source
                media="(min-width: 768px)"
                srcSet={SalonWebpMedium}
                type="image/webp"
              />
              {/* Fallback JPG format for medium screens */}
              <source
                media="(min-width: 768px)"
                srcSet={SalonJpgMedium}
                type="image/jpeg"
              />
              {/* WebP format for smaller screens */}
              <source srcSet={SalonWebpSmall} type="image/webp" />
              {/* Fallback JPG for browsers that do not support WebP */}
              <Image
                className="aboutTreatment"
                src={SalonJpgSmall} // The default src should be the smallest image size
                alt="Head Wellness Salon"
                srcSet={`${SalonJpgSmall} 400w, ${SalonJpgMedium} 800w, ${SalonJpgLarge} 1600w`}
                sizes="(max-width: 767px) 400px, (max-width: 1549px) 800px, 1600px"
              />
            </picture>
          </figure>
        </div>
      </article>
    </section>
  );
};

export default About;
