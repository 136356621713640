const faqsData = [
  { question: "faq.question1", answer: "faq.answer1" },
  { question: "faq.question2", answer: "faq.answer2" },
  { question: "faq.question3", answer: "faq.answer3" },
  { question: "faq.question4", answer: "faq.answer4" },
  { question: "faq.question5", answer: "faq.answer5" },
  { question: "faq.question6", answer: "faq.answer6" },
  { question: "faq.question7", answer: "faq.answer7" },
  { question: "faq.question8", answer: "faq.answer8" },
  { question: "faq.question9", answer: "faq.answer9" },
];

export default faqsData;
