import React, { useState, useRef, useEffect } from "react";
import TreatmentCard from "../../molecules/TreatmentCard/TreatmentCard";
import treatmentsData from "../../../constants/treatmentsData";
import Text from "../../atoms/Text/Text";
import BookingButton from "../../molecules/BookingButton/BookingButton";
import { useTranslation } from "react-i18next";
import "./treatment.scss";

const Treatment = () => {
  const listRef = useRef(null);
  const [focusedIndex, setFocusedIndex] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    let observerActive = true; // Flag to control observer's effect

    // Function to handle updates when the card is focused due to scrolling
    const updateFocusedCardOnScroll = () => {
      const scrollLeft = listRef.current.scrollLeft;
      const clientWidth = listRef.current.clientWidth;
      const scrollWidth = listRef.current.scrollWidth;

      const firstCard = listRef.current.firstChild;
      const cardStyle = window.getComputedStyle(firstCard);
      const cardWidth = firstCard.offsetWidth;
      const cardMarginLeft = parseFloat(cardStyle.marginLeft);
      const cardMarginRight = parseFloat(cardStyle.marginRight);

      const totalCardWidth = cardWidth + cardMarginLeft + cardMarginRight;

      // Bereken de index van de kaart die in het centrum van de lijst staat.
      const centerPosition = scrollLeft + clientWidth / 2;
      const index = Math.floor(centerPosition / totalCardWidth);

      // Controleer of de gebruiker helemaal naar links heeft gescrolld.
      if (scrollLeft === 0) {
        setFocusedIndex(0);
      }
      // Controleer of de gebruiker helemaal naar rechts heeft gescrolld.
      else if (scrollLeft + clientWidth >= scrollWidth) {
        setFocusedIndex(treatmentsData.length - 1);
      }
      // Zo niet, stel dan de focusindex in op basis van de huidige scrollpositie.
      else {
        setFocusedIndex(index);
      }
    };

    // Function to determine if all cards are visible and set the focused card
    const updateFocusedCard = () => {
      if (listRef.current) {
        const listWidth = listRef.current.offsetWidth;
        const cardWidth = listRef.current.firstChild.offsetWidth;
        const allCardsVisible = listWidth >= cardWidth * treatmentsData.length;

        const newFocusedIndex = allCardsVisible
          ? Math.floor(treatmentsData.length / 2)
          : 0;
        setFocusedIndex(newFocusedIndex);

        observerActive = false; // Disable observer temporarily
        setTimeout(() => (observerActive = true), 500); // Re-enable observer after a short delay
      }
    };

    // Immediate invocation to set the initial focused card
    updateFocusedCard();

    // Add event listener for window resize to recalculate focused card
    window.addEventListener("resize", updateFocusedCard);

    // Attach event listeners for touch events
    const listElement = listRef.current;
    if (listElement) {
      listElement.addEventListener("scroll", updateFocusedCardOnScroll, {
        passive: true,
      });
    }

    // Observer for detecting when cards are visible within the viewport
    const observer = new IntersectionObserver(
      (entries) => {
        if (observerActive) {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setFocusedIndex(Number(entry.target.getAttribute("data-index")));
            }
          });
        }
      },
      { root: listRef.current, threshold: 0.5 }
    );

    // Attach the observer to each card
    const cards = listRef.current ? listRef.current.children : [];
    Array.from(cards).forEach((card) => observer.observe(card));
    
   

    // Cleanup function
    return () => {
      window.removeEventListener("resize", updateFocusedCard);
      if (listElement) {
        listElement.removeEventListener("scroll", updateFocusedCardOnScroll);
      }
      observer.disconnect(); // Disconnect the observer to clean up all observations at once
    };

  }, []); // Empty dependency array ensures this runs once on mount

  // Function to update focused index on hover
  const handleMouseEnter = (index) => {
    setFocusedIndex(index);
  };

  // Function to handle the swipe left action
  const handleSwipeLeft = (index) => {
    if (index > 0) {
      setFocusedIndex(index - 1);
    }
  };

  // Function to handle the swipe right action
  const handleSwipeRight = (index) => {
    if (index < treatmentsData.length) {
      setFocusedIndex(index + 1);
    }
  };

  return (
    <section
      id="treatments"
      className="treatments"
      aria-labelledby="treatments-heading"
    >
      <div className="treatments__text">
        <header>
          <Text variant="h1" className="treatments-header">
            {t("treatments.header")}
          </Text>
        </header>
        <Text variant="p" className="treatment-info">
          {t("treatments.info")}
        </Text>
      </div>
      <div className="treatments__list" id="treatmentslist" ref={listRef}>
        {treatmentsData.length ? (
          treatmentsData.map((treatment, index) => (
            <TreatmentCard
              key={treatment.title}
              title={treatment.title}
              description={treatment.description}
              rules={treatment.rules}
              rules2={treatment.rules2}
              duration={treatment.duration}
              price={treatment.price}
              steps={treatment.steps}
              link={treatment.link}
              showDurationIcon={index < 5}
              onSwipeLeft={() => handleSwipeLeft(index)}
              onSwipeRight={() => handleSwipeRight(index)}
              canSwipeLeft={index > 0}
              canSwipeRight={index < treatmentsData.length - 1}
              onMouseEnter={() => handleMouseEnter(index)}
              isFocused={focusedIndex === index}
              data-index={index}
              totalTreatments={treatmentsData.length}
            />
          ))
        ) : (
          <p className="treatments__empty-message">{t("treatments.empty")}</p>
        )}
      </div>
      <BookingButton link="https://headwellness.zohobookings.eu/portal-embed#/customer/headwellness" />
    </section>
  );
};

export default Treatment;