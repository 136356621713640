import React, { useCallback, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Text from "../../atoms/Text/Text";
import LanguageSwitcher from "../../atoms/LanguageSwitcher/LanguageSwitcher";
import Logo from "../../atoms/Logo/Logo";
import "./termsAndPrivacy.scss";
import Icon from "../../atoms/Icon/Icon";

const TermsAndPrivacy = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const handleLogoClick = useCallback((event) => {
    // Prevent the event from bubbling up
    event.stopPropagation();

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const hash = location.hash.replace("#", "");
    if (hash) {
      const element = document.getElementById(hash);
      const headerHeight = document.querySelector(".header")?.offsetHeight || 0;
      if (element) {
        const position = element.offsetTop - headerHeight - 20;
        window.scrollTo({
          top: position,
          behavior: "smooth",
        });
      }
    }
  }, [location]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{t("helmet.termspolicy.policyTitle")}</title>
          <meta
            name="description"
            content={t("helmet.termspolicy.description")}
          />
        </Helmet>
      </HelmetProvider>

      <div
        className="termsandprivacy"
        aria-labelledby="terms-and-privacy-title"
      >
        <div className="header">
          <div className="left-section">
            <Icon
              name="home"
              link="/"
              label="Go to Home"
              target="_self"
              className="home-icon"
            />
          </div>
          <div className="center-section">
            <Logo onClick={handleLogoClick} />
          </div>
          <div className="right-section">
            <LanguageSwitcher />
          </div>
        </div>

        <section id="terms-section" className="terms">
          <header className="__title">
            <Text variant="h1" id="terms-title">
              {t("terms.title")}
            </Text>
          </header>
          <div className="__introduction">
            <Text variant="p" id="terms-introduction" className={"__paragraph"}>
              {t("terms.introduction")}
            </Text>
          </div>
          <div className="__definitions">
            <Text variant="h2" id="terms-definitions-title">
              {t("terms.definitions.title")}
            </Text>
            <Text variant="p" id="terms-definitions-understanded">
              {t("terms.definitions.understanded")}
            </Text>
            <ul>
              {t("terms.definitions.content", { returnObjects: true }).map(
                (item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                )
              )}
            </ul>
            <Text variant="p" className="__disclaimer __paragraph">
              {t("terms.disclaimer")}
            </Text>
          </div>

          <section id="appointment-section" className="appointment">
            <Text variant="h2" id="appointment-title">
              {t("terms.appointment.title")}
            </Text>

            <div className="appointment-making">
              <Text variant="h3" id="makingAppointment-title">
                {t("terms.appointment.makingAppointment.title")}
              </Text>
              <Text variant="p" id="makingAppointment">
                {t("terms.appointment.makingAppointment.makeAppointment")}
              </Text>
              <ol>
                {t("terms.appointment.makingAppointment.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ol>
            </div>

            <div className="appointment-cancellationPolicy">
              <Text variant="h3" id="cancellationPolicy-title">
                {t("terms.appointment.cancellationPolicy.title")}
              </Text>
              <ol>
                {t("terms.appointment.cancellationPolicy.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ol>
            </div>

            <div className="appointment-lateArrivals">
              <Text variant="h3" id="lateArrivals-title">
                {t("terms.appointment.lateArrivals.title")}
              </Text>
              <ul>
                {t("terms.appointment.lateArrivals.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>

            <div className="appointment-forceMajeure">
              <Text variant="h3" id="forceMajeure-title">
                {t("terms.appointment.forceMajeure.title")}
              </Text>
              <ul>
                {t("terms.appointment.forceMajeure.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>

            <div className="appointment-confirmation">
              <Text variant="p">{t("terms.confirmation")} </Text>
            </div>
          </section>

          <section id="payment-section" className="payment">
            <Text variant="h2" id="terms-payment-title">
              {t("terms.payment.title")}
            </Text>

            <div className="payment-pricesAndOffers">
              <Text variant="h3" id="terms-pricesAndOffers-title">
                {t("terms.payment.pricesAndOffers.title")}
              </Text>
              <ol>
                {t("terms.payment.pricesAndOffers.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ol>
            </div>

            <div className="payment-methodsOfPayment">
              <Text variant="h3" id="terms-methodsOfPayment-title">
                {t("terms.payment.methodsOfPayment.title")}
              </Text>
              <ol>
                {t("terms.payment.methodsOfPayment.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ol>
            </div>

            <div className="payment-giftVoucher">
              <Text variant="h3" id="terms-giftVoucher-title">
                {t("terms.payment.giftVoucher.title")}
              </Text>
              <ol>
                {t("terms.payment.giftVoucher.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ol>
            </div>
          </section>

          <section id="liability-section" className="liability">
            <Text variant="h2" id="terms-liability-title">
              {t("terms.liability.title")}
            </Text>

            <div className="liability-disclaimerOfLiability">
              <Text variant="h3" id="terms-disclaimerOfLiability-title">
                {t("terms.liability.disclaimerOfLiability.title")}
              </Text>
              <ul>
                {t("terms.liability.disclaimerOfLiability.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>

            <div className="liability-clientLiability">
              <Text variant="h3" id="terms-clientLiability-title">
                {t("terms.liability.clientLiability.title")}
              </Text>
              <ul>
                {t("terms.liability.clientLiability.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>

            <div className="liability-personalBelongings">
              <Text variant="h3" id="terms-personalBelongings-title">
                {t("terms.liability.personalBelongings.title")}
              </Text>
              <ul>
                {t("terms.liability.personalBelongings.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>

            <div className="liability-theft">
              <Text variant="h3" id="terms-theft-title">
                {t("terms.liability.theft.title")}
              </Text>
              <ul>
                {t("terms.liability.theft.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>
          </section>

          <section id="healthSafety-section" className="healthSafety">
            <Text variant="h2" id="terms-healthSafety-title">
              {t("terms.healthSafety.title")}
            </Text>

            <div className="healthSafety-serviceOffering">
              <Text variant="h3" id="terms-serviceOffering-title">
                {t("terms.healthSafety.serviceOffering.title")}
              </Text>
              <ul>
                {t("terms.healthSafety.serviceOffering.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>

            <div className="healthSafety-treatmentInformation">
              <Text variant="h3" id="terms-treatmentInformation-title">
                {t("terms.healthSafety.treatmentInformation.title")}
              </Text>
              <ul>
                {t("terms.healthSafety.treatmentInformation.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>

            <div className="healthSafety-serviceTerms">
              <Text variant="h3" id="terms-serviceTerms-title">
                {t("terms.healthSafety.serviceTerms.title")}
              </Text>
              <ul>
                {t("terms.healthSafety.serviceTerms.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>

            <div className="healthSafety-contraindications">
              <Text variant="h3" id="terms-contraindications-title">
                {t("terms.healthSafety.contraindications.title")}
              </Text>
              <ul>
                {t("terms.healthSafety.contraindications.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>

            <div className="healthSafety-clientResponsibility">
              <Text variant="h3" id="terms-clientResponsibility-title">
                {t("terms.healthSafety.clientResponsibility.title")}
              </Text>
              <ul>
                {t("terms.healthSafety.clientResponsibility.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>
          </section>

          <section id="disputeResolution-section" className="disputeResolution">
            <Text variant="h2" id="terms-disputeResolution-title">
              {t("terms.disputeResolution.title")}
            </Text>

            <div className="disputeResolution-amicableSettlement">
              <Text variant="h3" id="terms-amicableSettlement-title">
                {t("terms.disputeResolution.amicableSettlement.title")}
              </Text>
              <ul>
                {t("terms.disputeResolution.amicableSettlement.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>

            <div className="disputeResolution-complaintProcedure">
              <Text variant="h3" id="terms-complaintProcedure-title">
                {t("terms.disputeResolution.complaintProcedure.title")}
              </Text>
              <ul>
                {t("terms.disputeResolution.complaintProcedure.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>

            <div className="disputeResolution-complaintHandling">
              <Text variant="h3" id="terms-complaintHandling-title">
                {t("terms.disputeResolution.complaintHandling.title")}
              </Text>
              <ul>
                {t("terms.disputeResolution.complaintHandling.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>

            <div className="disputeResolution-disputeEscalation">
              <Text variant="h3" id="terms-disputeEscalation-title">
                {t("terms.disputeResolution.disputeEscalation.title")}
              </Text>
              <ul>
                {t("terms.disputeResolution.disputeEscalation.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>
          </section>

          <section id="properConduct-section" className="properConduct">
            <Text variant="h2" id="terms-properConduct-title">
              {t("terms.properConduct.title")}
            </Text>

            <div className="properConduct-generalNorms">
              <Text variant="h3" id="terms-generalNorms-title">
                {t("terms.properConduct.generalNorms.title")}
              </Text>
              <ul>
                {t("terms.properConduct.generalNorms.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>

            <div className="properConduct-refusalRight">
              <Text variant="h3" id="terms-refusalRight-title">
                {t("terms.properConduct.refusalRight.title")}
              </Text>
              <ul>
                {t("terms.properConduct.refusalRight.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>

            <div className="properConduct-hygieneRules">
              <Text variant="h3" id="terms-hygieneRules-title">
                {t("terms.properConduct.hygieneRules.title")}
              </Text>
              <ul>
                {t("terms.properConduct.hygieneRules.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>

            <div className="properConduct-companionsInWaitingArea">
              <Text variant="h3" id="terms-companionsInWaitingArea-title">
                {t("terms.properConduct.companionsInWaitingArea.title")}
              </Text>
              <ul>
                {t("terms.properConduct.companionsInWaitingArea.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>

            <div className="properConduct-accompanyingChildren">
              <Text variant="h3" id="terms-accompanyingChildren-title">
                {t("terms.properConduct.accompanyingChildren.title")}
              </Text>
              <ul>
                {t("terms.properConduct.accompanyingChildren.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>
          </section>

          <section id="applicableLaw-section" className="applicableLaw">
            <Text variant="h2" id="terms-applicableLaw-title">
              {t("terms.applicableLaw.title")}
            </Text>
            <ul>
              {t("terms.applicableLaw.description", {
                returnObjects: true,
              }).map((item, index) => (
                <li key={index}>
                  <Text variant="p">{item}</Text>
                </li>
              ))}
            </ul>
          </section>

          <section
            id="intellectualProperty-section"
            className="intellectualProperty"
          >
            <Text variant="h2" id="terms-intellectualProperty-title">
              {t("terms.intellectualProperty.title")}
            </Text>
            <ul>
              {t("terms.intellectualProperty.description", {
                returnObjects: true,
              }).map((item, index) => (
                <li key={index}>
                  <Text variant="p">{item}</Text>
                </li>
              ))}
            </ul>
          </section>

          <section id="serviceChanges-section" className="serviceChanges">
            <Text variant="h2" id="terms-serviceChanges-title">
              {t("terms.serviceChanges.title")}
            </Text>
            <ul>
              {t("terms.serviceChanges.description", {
                returnObjects: true,
              }).map((item, index) => (
                <li key={index}>
                  <Text variant="p">{item}</Text>
                </li>
              ))}
            </ul>
          </section>

          <section id="termsChanges-section" className="termsChanges">
            <Text variant="h2" id="terms-termsChanges-title">
              {t("terms.termsChanges.title")}
            </Text>

            <div className="termsChanges-rightToAmend">
              <Text variant="h3" id="terms-rightToAmend-title">
                {t("terms.termsChanges.rightToAmend.title")}
              </Text>
              <ul>
                {t("terms.termsChanges.rightToAmend.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>

            <div className="termsChanges-amendmentEffectiveDate">
              <Text variant="h3" id="terms-amendmentEffectiveDate-title">
                {t("terms.termsChanges.amendmentEffectiveDate.title")}
              </Text>
              <ul>
                {t("terms.termsChanges.amendmentEffectiveDate.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>

            <div className="termsChanges-amendmentNotification">
              <Text variant="h3" id="terms-amendmentNotification-title">
                {t("terms.termsChanges.amendmentNotification.title")}
              </Text>
              <ul>
                {t("terms.termsChanges.amendmentNotification.description", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>
                    <Text variant="p">{item}</Text>
                  </li>
                ))}
              </ul>
            </div>
          </section>

          <section id="acceptanceOfTerms-section" className="acceptanceOfTerms">
            <Text variant="h2" id="terms-acceptanceOfTerms-title">
              {t("terms.acceptanceOfTerms.title")}
            </Text>
            <ul>
              {t("terms.acceptanceOfTerms.description", {
                returnObjects: true,
              }).map((item, index) => (
                <li key={index}>
                  <Text variant="p">{item}</Text>
                </li>
              ))}
            </ul>
          </section>

          {/* Similar structure for other sections like afspraak, payment, liability, etc. */}
        </section>

        <section
          id="privacy-policy-section"
          className="privacypolicy"
          aria-labelledby="privacy-policy-title"
        >
          <header className="__title">
            <Text variant="h1" id="privacy-policy-title">
              {t("policy.title")}
            </Text>
          </header>
          <header>
            <Text variant="h2" className="__header">
              {t("policy.header1")}
            </Text>
          </header>{" "}
          <Text variant="p" className="__paragraph">
            {t("policy.introduction")}
          </Text>
          <header>
            <Text variant="h2" className="__header">
              {t("policy.header2")}
            </Text>
          </header>{" "}
          <Text variant="p" className="__paragraph">
            {t("policy.what")}
          </Text>
          <header>
            <Text variant="h2" className="__header">
              {t("policy.header3")}
            </Text>
          </header>
          <Text variant="p" className="__paragraph">
            {t("policy.how")}
          </Text>
          <header>
            <Text variant="h2" className="__header">
              {t("policy.header4")}
            </Text>
          </header>{" "}
          <Text variant="p" className="__paragraph">
            {t("policy.usage")}
          </Text>
          <header>
            <Text variant="h2" className="__header">
              {t("policy.header5")}
            </Text>
          </header>{" "}
          <Text variant="p" className="__paragraph">
            {t("policy.sharing")}
          </Text>
          <header>
            <Text variant="h2" className="__header">
              {t("policy.header6")}
            </Text>
          </header>
          <Text variant="p" className="__paragraph">
            {t("policy.security")}
          </Text>
          <header>
            <Text variant="h2" className="__header">
              {t("policy.header7")}
            </Text>
          </header>
          <Text variant="p" className="__paragraph">
            {t("policy.rights")}
          </Text>
          <header>
            <Text variant="h2" className="__header">
              {t("policy.header8")}
            </Text>
          </header>
          <Text variant="p" className="__paragraph">
            {t("policy.tracking")}
          </Text>
          <Text variant="p" className="__paragraph">
            {t("policy.tracking2")}{" "}
            <a href="https://tools.google.com/dlpage/gaoptout">
              https://tools.google.com/dlpage/gaoptout
            </a>
          </Text>
          <header>
            <Text variant="h2" className="__header">
              {t("policy.header9")}
            </Text>
          </header>{" "}
          <Text variant="p" className="__paragraph">
            {t("policy.changes")}
          </Text>
          <div className="line"></div>
          <div className="contact">
            <header>
              <Text variant="h2" className="__header">
                {t("policy.header10")}
              </Text>
            </header>

            <Text variant="p" className="__contact __paragraph">
              {t("policy.contact")}
            </Text>
            <Text variant="p" className="__paragraph">
              Head Wellness
            </Text>
            <Text variant="p" className="__paragraph">
              Prozastraat 111B, 1321 GZ Almere
            </Text>
            <Text variant="p" className="__paragraph">
              {t("policy.kvk")}
            </Text>
            <Text variant="p" className="__paragraph">
              {t("policy.call")}
            </Text>
            <Text variant="p" className="__paragraph">
              {t("policy.email")}
            </Text>
          </div>
        </section>
      </div>
    </>
  );
};

export default TermsAndPrivacy;
