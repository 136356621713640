import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "../../components/pages/HomePage/HomePage";
import TermsAndPrivacy from "../../components/pages/TermsAndPrivacy/TermsAndPrivacy";
import NotFound from "../../components/pages/NotFound/NotFound";
import ThankYouPage from "../../components/pages/ThankYou/ThankYou";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/terms-and-privacy" element={<TermsAndPrivacy />} />
        <Route path="/treatments" element={<HomePage />} /> 
        <Route path="/booking-confirmed" element={<ThankYouPage/>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};


export default App;