import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Icon from "../../atoms/Icon/Icon";
import "./notFound.scss";
import Text from "../../atoms/Text/Text";
import LanguageSwitcher from "../../atoms/LanguageSwitcher/LanguageSwitcher";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{t("helmet.notfound.notfoundTitle")}</title>
          <meta
            name="description"
            content={t("helmet.termspolicy.description")}
          />
        </Helmet>
      </HelmetProvider>

      <div className="right">
        <LanguageSwitcher />
      </div>
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-content">
            <div className="notfound-emote">
              <h1>:(</h1>
            </div>
            <div className="notfound-text">
              <Text variant="h2">{t("notfound.404")}</Text>
              <Text variant="p">{t("notfound.text")}</Text>

              <Icon
                name="home"
                link="/"
                label="Go to Home"
                target="_self"
                className="home-icon"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
