import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Header from "../../organisms/Header/Header";
import Subheader from "../../organisms/Subheader/Subheader";
import BannerImage from "../../organisms/BannerImage/BannerImage";
import About from "../../organisms/About/About";
import Treatment from "../../organisms/Treatment/Treatment";
import Contact from "../../organisms/Contact/Contact";
import Faq from "../../organisms/Faq/Faq";
import Footer from "../../organisms/Footer/Footer";

const HomePage = () => {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/treatments') {
      setTimeout(() => {
        const element = document.getElementById('treatmentslist');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });

          // Reset URL after scrolling
          window.history.replaceState(null, '', '/');
        }
      }, 1500); // Adjust time as needed
    }
  }, [location]);
  
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta name="description" content={t("helmet.homepage.description")} />
          <meta name="keywords" content={t("helmet.homepage.keywords")} />
          {/* Add more meta tags for SEO as needed */}
        </Helmet>
      </HelmetProvider>

      <div>
        <Header />
        <Subheader />
        <BannerImage />
        <About />
        <Treatment />
        <Contact />
        <Faq />
        <Footer />
        {/* Other components specific to the home page */}
      </div>
    </>
  );
};

export default HomePage;
