import React, { useCallback, useEffect, useRef } from "react";
import NavItem from "../../atoms/NavItem/NavItem";
import { NAV_ITEMS } from "../../../constants/navConfig";
import "./dropdownMobile.scss";
import Icon from "../../atoms/Icon/Icon";

const DropdownMobile = ({ isOpen, onToggle, activeSection }) => {
  const dropdownRef = useRef(null);

  const handleDropdownClose = useCallback(() => {
    if (isOpen) {
      onToggle();
    }
  }, [isOpen, onToggle]);

  const handleClickOutside = useCallback(
    (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleDropdownClose();
      }
    },
    [handleDropdownClose]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className="dropdownMobile" ref={dropdownRef}>
      <button
        onClick={(e) => {
          e.stopPropagation();
          onToggle();
        }}
      >
        {isOpen ? <Icon name="close" label="Close Dropdown" /> : <Icon name="menu" label="Navigation menu" />}
      </button>

      <div className={`dropdownList ${isOpen ? "expanded" : "collapsed"}`}>
        <ul>
          {NAV_ITEMS.map((item) => (
            <NavItem
              key={item.keyName}
              keyName={item.keyName}
              to={item.to}
              isExternal={item.isExternal}
              isActive={activeSection === item.to} // Highlight based on the active section
              setExpanded={handleDropdownClose}
            />
          ))}
          <div className="icons">
            <div className="social-icons">
              <Icon
                name="instagram"
                link="https://www.instagram.com/headwellness/"
                label="Instagram"
                className="instagram-icon"
              />
              <Icon
                name="tiktok"
                link="https://www.tiktok.com/@headwellness"
                label="TikTok"
                className="tiktok-icon"
              />
              <Icon
                name="facebook"
                link="https://www.facebook.com/headwellness"
                label="Facebook"
                className="facebook-icon"
              />
            </div>
            <div className="contact-icons">
              <Icon
                name="email"
                link="mailto:info@headwellness.nl"
                label="Email"
              />
              <Icon
                name="whatsapp"
                link="https://wa.me/message/V4DNBNMYGQW6B1"
                label="Whatsapp"
              />
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default DropdownMobile;
