// Text.js
import React from "react";
import PropTypes from "prop-types";
import "./text.scss";

const Text = ({ variant, children, className }) => {
  const Tag = variant;

  return <Tag className={className}>{children}</Tag>;
};

Text.propTypes = {
  variant: PropTypes.oneOf([
    "span",
    "p",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "li",
    "ul",
    "ol",
  ]).isRequired,
  children: PropTypes.node,
  className: PropTypes.string, // This is optional, so no need for isRequired
};

Text.defaultProps = {
  className: "", // Default value can be an empty string or null
};

export default Text;
