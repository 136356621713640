import React from "react";
import PropTypes from "prop-types";
import "./button.scss";

const Button = ({ variant, size, isFullWidth, children, ...props }) => {
  return (
    <button
      className={`btn btn-${variant} btn-${size} ${
        isFullWidth ? "full-width" : ""
      }`}
      {...props}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  isFullWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func, // if this button is always used as a click button
};

Button.defaultProps = {
  variant: "primary",
  size: "medium",
  isFullWidth: false,
};

export default Button;
