import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import Icon from "../atoms/Icon/Icon";
import "./modal.scss";

const Modal = ({ children, onClose }) => {
  useEffect(() => {
    // Disable scrolling on the body when the modal is open
    const originalOverflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";

    // Function to call `onClose` when the escape key is pressed
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    // Add event listeners
    window.addEventListener("keydown", handleEscape);

    // Cleanup function
    return () => {
      // Re-enable scrolling when the modal is closed
      document.body.style.overflow = originalOverflow;
      // Remove event listener when the modal is closed
      window.removeEventListener("keydown", handleEscape);
    };
  }, [onClose]);

  // Function to close the modal if the backdrop is clicked
  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div className="modal-backdrop" onClick={handleBackdropClick}>
      <div className="close-modal">
        <button className="close-button" onClick={onClose}>
          <Icon name="close" label="Close pop-up" />
        </button>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
