import React from "react";
import NavItem from "../../atoms/NavItem/NavItem"; // Adjust the path if needed
import Icon from "../../atoms/Icon/Icon";
import { NAV_ITEMS } from "../../../constants/navConfig"; // Adjust the path to navConfig.js
import "./navbarDesktop.scss";

const NavbarDesktop = ({ activeSection }) => {
  return (
    <nav className="navbar">
      <ul className="navbar-list">
        {NAV_ITEMS.map((item) => (
          <NavItem
            key={item.keyName}
            keyName={item.keyName}
            to={item.to}
            isExternal={item.isExternal}
            isActive={activeSection === item.to}
          />
        ))}
      </ul>
      <div className="social-icons">
        <Icon
          name="instagram"
          link="https://www.instagram.com/headwellness/"
          label="Instagram"
          className="instagram-icon"
        />
        <Icon
          name="tiktok"
          link="https://www.tiktok.com/@headwellness"
          label="TikTok"
          className="tiktok-icon"
        />
        <Icon
          name="facebook"
          link="https://www.facebook.com/headwellness"
          label="Facebook"
          className="facebook-icon"
        />
        {/* Add more icons as needed */}
      </div>
    </nav>
  );
};

export default NavbarDesktop;
