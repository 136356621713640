// ImageSection.js
import React from "react";
import Image from "../../atoms/Image/Image";
import BannerImageLarge from "../../../assets/images/Banner/massage1600.jpg";
import BannerImageMedium from "../../../assets/images/Banner/massage800.jpg";
import BannerImageSmall from "../../../assets/images/Banner/massage400.jpg";
import BannerImageLargeWebP from "../../../assets/images/Banner/massage1600.webp";
import BannerImageMediumWebP from "../../../assets/images/Banner/massage800.webp";
import BannerImageSmallWebP from "../../../assets/images/Banner/massage400.webp";
import "./bannerImage.scss";

const BannerImage = () => (
  <section className="image-section">
    <picture>
      <source
        type="image/webp"
        srcSet={`
          ${BannerImageSmallWebP} 500w, 
          ${BannerImageMediumWebP} 1000w, 
          ${BannerImageLargeWebP} 8256w
        `}
        sizes="(max-width: 320px) 280px,
       (max-width: 480px) 440px,
       (max-width: 768px) 768px,
       1920px"
      />
      <Image
        className={"banner"}
        src={BannerImageLarge}
        alt="A soothing massage therapy session"
        srcSet={`
          ${BannerImageSmall} 500w, 
          ${BannerImageMedium} 1000w, 
          ${BannerImageLarge} 8256w
        `}
        sizes="(max-width: 320px) 280px,
       (max-width: 480px) 440px,
       (max-width: 768px) 768px,
       1920px"
      />
    </picture>
  </section>
);

export default BannerImage;
