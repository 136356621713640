export function throttle(func, delay) {
  let lastCall = 0;

  return function (...args) {
    const now = performance.now();

    if (now - lastCall < delay) {
      return;
    }

    lastCall = now;
    return func.apply(this, args);
  };
}
