import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { useTranslation } from "react-i18next";
import Text from "../../atoms/Text/Text";
import LanguageSwitcher from "../../atoms/LanguageSwitcher/LanguageSwitcher";
import Logo from "../../atoms/Logo/Logo";
import Icon from "../../atoms/Icon/Icon";
import "./thankYou.scss";

const ThankYouPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [bookingDetails, setBookingDetails] = useState({});

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const bookingId = searchParams.get("booking_id");
    const service = searchParams.get("service_name");
    const customerName = searchParams.get("customer_name");
    const startTime = searchParams.get("booking_start_time");
    const endTime = searchParams.get("booking_end_time");

    const formattedStartTime = startTime
      ? format(parseISO(startTime), "yyyy-MM-dd HH:mm")
      : "";
    const formattedEndTime = endTime
      ? format(parseISO(endTime), "yyyy-MM-dd HH:mm")
      : "";

    setBookingDetails({
      bookingId,
      service,
      customerName,
      startTime: formattedStartTime,
      endTime: formattedEndTime,
    });
  }, [location]);

  // Ensure start and end times are available before using them
  const startDate = bookingDetails.startTime
    ? bookingDetails.startTime.split(" ")[0]
    : null;
  const startTime = bookingDetails.startTime
    ? bookingDetails.startTime.split(" ")[1]
    : null;
  const endTime = bookingDetails.endTime
    ? bookingDetails.endTime.split(" ")[1]
    : null;

  if (!startDate || !startTime || !endTime) {
    return (
      <div className="booking-notfound">
        <div className="home">
          <Icon
            name="home"
            link="/"
            label="Go to Home"
            target="_self"
            className="home-icon"
          />
        </div>
        <div className="languageSwitcher">
          <LanguageSwitcher />
        </div>
        <Text variant="h2">{t("bookingConfirm.notFound")}</Text>
      </div>
    );
  }

  return (
    <div className="confirmed-page">
      <div className="home">
        <Icon
          name="home"
          link="/"
          label="Go to Home"
          target="_self"
          className="home-icon"
        />
      </div>
      <div className="languageSwitcher">
        <LanguageSwitcher />
      </div>

      <div className="confirm-section">
        <div className="logo">
          <Logo />
        </div>
        <Text variant="h1" className="title">
          {t("bookingConfirm.title")}
        </Text>
        <Text variant="h5" className={"customername"}>
          {t("bookingConfirm.thankYou", { name: bookingDetails.customerName })}
        </Text>
        <Text variant="p" className={"bookingdetails"}>
          {t("bookingConfirm.appointmentDetails", {
            service: bookingDetails.service,
            time: bookingDetails.startTime,
          })}
        </Text>        

        <Text variant="p" className={"readrules"}>
          {t("bookingConfirm.readrules")}
        </Text>

        <AddToCalendarButton
          name={`Appointment for ${bookingDetails.service}`}
          description={`Appointment with Head Wellness for ${bookingDetails.service}`}
          options={["Apple", "Google", "Microsoft365", "Outlook.com", "iCal"]}
          location="Prozastraat 111B, 1321 GZ Almere"
          startDate={startDate}
          startTime={startTime}
          endTime={endTime}
          timeZone="Europe/Amsterdam"
          iCalFileName="headwellness_appointment"
          buttonStyle="round"
          label={t("bookingConfirm.button")}
        />
      </div>
    </div>
  );
};

export default ThankYouPage;
