// TreatmentCard.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import "./treatmentCard.scss";
import Text from "../../atoms/Text/Text";
import { useTranslation } from "react-i18next";
import Modal from "../../Modal/Modal";
import Icon from "../../atoms/Icon/Icon";
import Image from "../../atoms/Image/Image";
import womendeluxewebp from "../../../assets/images/Cards/womendeluxe800.webp";
import womendeluxejpg from "../../../assets/images/Cards/womendeluxe800.jpg";
import womenultradeluxewebp from "../../../assets/images/Cards/womenultradeluxe800.webp";
import womenultradeluxejpg from "../../../assets/images/Cards/womenultradeluxe800.jpg";
import mendeluxewebp from "../../../assets/images/Cards/mendeluxe800.webp";
import mendeluxejpg from "../../../assets/images/Cards/mendeluxe800.jpg";
import menultradeluxewebp from "../../../assets/images/Cards/menultradeluxe800.webp";
import menultradeluxejpg from "../../../assets/images/Cards/menultradeluxe800.jpg";
import kidsdeluxewebp from "../../../assets/images/Cards/kidsdeluxe800.webp";
import kidsdeluxejpg from "../../../assets/images/Cards/kidsdeluxe800.jpg";
import giftcardwebp from "../../../assets/images/Cards/giftcard800.webp";
import giftcardjpg from "../../../assets/images/Cards/giftcard800.jpg";
import BookingButton from "../../molecules/BookingButton/BookingButton";

const TreatmentCard = ({
  title,
  duration,
  price,
  description,
  rules,
  rules2,
  steps,
  link,
  showDurationIcon,
  index,
  isFocused,
  onMouseEnter,
}) => {
  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState(false);

  const stepsKeys = steps ? Object.keys(steps) : [];
  const imageKey =
    title === "treatments.treatmentCards.giftcard.title" ? t(title) : title;
  const images = {
    "Women Deluxe": {
      webp: womendeluxewebp,
      fallback: womendeluxejpg,
    },
    "Women Ultra Deluxe": {
      webp: womenultradeluxewebp,
      fallback: womenultradeluxejpg,
    },
    "Men Deluxe": {
      webp: mendeluxewebp,
      fallback: mendeluxejpg,
    },
    "Men Ultra Deluxe": {
      webp: menultradeluxewebp,
      fallback: menultradeluxejpg,
    },
    "Kids Deluxe": {
      webp: kidsdeluxewebp,
      fallback: kidsdeluxejpg,
    },
    [t("treatments.treatmentCards.giftcard.title")]: {
      webp: giftcardwebp,
      fallback: giftcardjpg,
    },
    // Add mappings for other treatment titles and their images
  };

  const currentImage = images[imageKey] || {};

  return (
    <>
      <div
        className={`treatment-card ${isFocused ? "focused" : ""}`}
        onClick={() => setExpanded(true)}
        onMouseEnter={() => onMouseEnter(index)}
        data-index={index}
      >
        <picture>
          <source type="image/webp" srcSet={currentImage.webp} />
          <Image
            className="treatment-card__image"
            src={currentImage.fallback}
            alt={`Image for ${title}`}
            loading="lazy"
          />
        </picture>
        <Text variant="h3" className="treatment-card__title">
          {t(title)}
        </Text>
        <Text variant="p" className="treatment-card__description">
          {t(description)}
        </Text>

        <div className="treatment-card__divider"></div>

        <div className="treatment-card__container">
          <div className="treatment-card__duration-price-container">
            <div className="treatment-card__duration-container">
              {showDurationIcon && (
                <Icon
                  name="duration"
                  label="Duration"
                  className="treatment-card__duration-icon"
                />
              )}
              {duration && (
                <Text variant="p" className="treatment-card__duration">
                  {t(duration)}
                </Text>
              )}
            </div>
            {price && (
              <Text variant="p" className="treatment-card__price">
                {price}
              </Text>
            )}
          </div>

          <Icon
            name="seeMore"
            label="More information"
            className={"treatment-card__seemore-icon"}
          />
        </div>
      </div>

      {isExpanded && (
        <Modal onClose={() => setExpanded(false)}>
          <picture>
            <source type="image/webp" srcSet={currentImage.webp} />
            <Image
              className="treatment-card__image"
              src={currentImage.fallback}
              alt={`Image for ${title}`}
            />
          </picture>
          <Text variant="h3" className="treatment-card__title">
            {t(title)}
          </Text>
          {rules && rules2 && (
            <div>
              <Text variant="p" className="treatment-card__rules">
                {t(rules)}
              </Text>
              <Text variant="p" className="treatment-card__rules2">
                {t(rules2)}
              </Text>
            </div>
          )}

          {stepsKeys.map((key, index) => (
            <div key={index}>
              <Text variant="h4" className="__step-header">
                {t(steps[key].header)}
              </Text>
              <Text variant="p" className="__step-detail">
                {t(steps[key].detail)}
              </Text>
            </div>
          ))}

          <div className="__divider"></div>

          {duration || price ? (
            <div className="treatment-card__duration-price-container">
              {duration && (
                <div className="treatment-card__duration-container">
                  {showDurationIcon && (
                    <Icon
                      name="duration"
                      label="Duration"
                      className="treatment-card__duration-icon"
                    />
                  )}
                  <Text variant="p" className="treatment-card__duration">
                    {t(duration)}
                  </Text>
                </div>
              )}
              {price && (
                <Text variant="p" className="treatment-card__price">
                  {price}
                </Text>
              )}
            </div>
          ) : null}

          <BookingButton link={link} />
        </Modal>
      )}
    </>
  );
};

TreatmentCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  rules: PropTypes.string,
  rules2: PropTypes.string,
  duration: PropTypes.string,
  price: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      detail: PropTypes.string,
    })
  ),
  link: PropTypes.string,
  showDurationIcon: PropTypes.bool,
  onSwipeLeft: PropTypes.func,
  onSwipeRight: PropTypes.func,
  index: PropTypes.number,
  isFocused: PropTypes.bool,
  totalTreatments: PropTypes.number.isRequired, // Add this to ensure proper validation
  onMouseEnter: PropTypes.func.isRequired,
};

export default TreatmentCard;
