import React from "react";
import PropTypes from "prop-types";
import { Link as ScrollLink } from "react-scroll";
import { useTranslation } from "react-i18next";
import "./navItem.scss";

const NavItem = ({ keyName, to, isExternal, isActive, setExpanded }) => {
  const { t } = useTranslation();

  const content = <span>{t(keyName)}</span>; // Ensuring content is consistent for both link types

  const handleLinkClick = () => {
    if (setExpanded) {
      setExpanded(false); // Close the navbar dropdown when a nav item is clicked
    }
  };

  const link = isExternal ? (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      className="nav-link"
      onClick={handleLinkClick}
    >
      {content}
    </a>
  ) : (
    <ScrollLink
      to={to}
      spy={true}
      smooth={true}
      duration={500}
      className={`nav-link ${isActive ? "active" : ""}`}
      aria-label={`Scroll to ${t(keyName)}`}
      onClick={handleLinkClick}
    >
      {content}
    </ScrollLink>
  );

  return <li className={`nav-item ${isActive ? "active" : ""}`}>{link}</li>;
};

NavItem.propTypes = {
  keyName: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isExternal: PropTypes.bool,
  isActive: PropTypes.bool,
  setExpanded: PropTypes.func, 
};

NavItem.defaultProps = {
  isExternal: false,
  isActive: false,
};

export default NavItem;
