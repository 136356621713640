import React from "react";
import { useTranslation } from "react-i18next";
import Icon from "../../atoms/Icon/Icon";
import Text from "../../atoms/Text/Text";
import "./contact.scss";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <section id="contact" className="contact-section" role="main">
      <div className="get-in-touch-section">
        <div className="contact">
          <header>
            <Text variant="h1" className={"contact_header"}>
              {t("contact.header")}
            </Text>
          </header>

          <div className="contact-icons">
            <Icon
              name="whatsapp"
              link="https://wa.me/message/V4DNBNMYGQW6B1"
              label="Message us"
              className={"whatsapp"}
            />
            <Icon
              name="email"
              link="mailto:info@headwellness.nl"
              label="Email: info@headwellness.nl"
              className={"email"}
            />
            <Icon
              name="phone"
              link={`tel:+31 652533051`}
              label="Call: +31 652533051"
              className={"phone"}
            />
          </div>
          <Text variant="p" className={"faq"}>
            {t("contact.faq")}
          </Text>
        </div>
        <div className="address-section">
          <header>
            <Text variant="h2">{t("contact.visit")}</Text>
          </header>
          <address>
            <a
              href="https://maps.app.goo.gl/8Nmr7cedfNZwdW359"
              target="_blank"
              rel="noopener noreferrer"
            >
              Prozastraat 111B <br /> 1321 GZ Almere
            </a>
          </address>
          <Text variant="p" className={"location"}>
            {t("contact.location")}
          </Text>
          <Text variant="p" className={"entry"}>
            {t("contact.entry")}
          </Text>
          <div className="opening-hours-wrapper">
            <Text variant="p" className={"openinghours-title"}>
              {t("contact.openingHours")}
            </Text>
            <div className="opening-hours-container">
              {[
                "monday",
                "tuesday",
                "wednesday",
                "thursday",
                "friday",
                "saturday",
                "sunday",
              ].map((day) => (
                <div key={day} className="opening-hours-row">
                  <Text variant="span" className="day">
                    {t(`contact.daysandHours.${day}.day`)}
                  </Text>
                  <Text variant="span" className="hours">
                    {t(`contact.daysandHours.${day}.hours`)}
                  </Text>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="map-section">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2436.705152994357!2d5.1828278!3d52.357632699999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c611c3183c8ee5%3A0x8b7c652d5196162d!2sHead%20Wellness!5e0!3m2!1snl!2snl!4v1700591913795!5m2!1snl!2snl"
          allowFullScreen=""
          loading="lazy"
          title={"Head Wellness map"}
          referrerPolicy="no-referrer-when-downgrade"
        >
          aria-hidden="true"
        </iframe>
      </div>
    </section>
  );
};

export default Contact;
